import type { BrandOfferListingQuery as BrandStaticOfferOptionsQuery } from '@dx-ui/content-offers-components';
import { OfferListing, serverSideBrandOfferListingQuery } from '@dx-ui/content-offers-components';
import Head from 'next/head';
import { getServerSideClients } from '@dx-ui/framework-react-query';
import { OffersHeader } from '../../components/header';
import { OffersFooter } from '../../components/footer';
import { useRouter } from 'next/dist/client/router';
import { ViewDetailsLink } from '../../components/view-details';
import type { GetServerSideProps } from 'next';
import { ASSETS_URI, OHW_BASE_URL_TRIMMED, queryClientProps } from '../../utils/env-vars';
import { serverSideTranslations } from '../../utils/server-side-translations';
import type { PageMetricsListing } from '../../components/analytics';
import { Analytics } from '../../components/analytics';
import {
  serverSideBrandQuery,
  serverSideFeatureTogglesQuery,
  serverSideNavigationQuery,
} from '../../generated/react-query';
import {
  sendCTAClickedOfferId,
  useConductricsSortOrder,
  useInitializeConductrics,
} from '../../utils/conductrics-sort-order';
import { dehydrate } from '@tanstack/react-query';
import { sendReward } from '@dx-ui/framework-conductrics';
import {
  ENTERPRISE_THEME_REFRESH_TOGGLE_NAME,
  getBrandRefreshProps,
} from '@dx-ui/osc-styles/utils/theme-toggles';

// NHCBP-5467 - conductrics test code for non-ca and usa users
function sendNonCanUsaReward(offerId: number) {
  switch (offerId) {
    case 2000000046:
      sendReward('g-2xpoints');
      break;
    case 2000000047:
      sendReward('g-honorsdiscountadvancepurchase');
      break;
    case 2000000544:
      sendReward('g-pointspluspromotion');
      break;
    default:
      break;
  }
}

export function Index({
  seo,
  metrics,
}: {
  seo?: NonNullable<NonNullable<BrandStaticOfferOptionsQuery['brand']>['page']>['seo'];
  metrics: PageMetricsListing;
}) {
  const { locale } = useRouter();
  useInitializeConductrics();
  const sortOrder = useConductricsSortOrder();
  return (
    <>
      <Analytics metrics={metrics} />
      <Head>
        <title>{seo?.pageTitle}</title>
        <meta name="keywords" content={seo?.metaKeywords || ''} />
        <meta name="description" content={seo?.metaDescription || ''} />
        {seo?.hideSearchEngine ? <meta name="robots" content="noindex,follow" /> : null}

        <link rel="canonical" href={`${OHW_BASE_URL_TRIMMED}/${locale}/offers/`} />
      </Head>
      <OffersHeader />
      <div className="container mx-auto my-4">
        <OfferListing
          sortOffers={sortOrder}
          language="en"
          brandCode="WW"
          assetUrl={ASSETS_URI}
          renderAdditionalComponents={(data) => {
            return (
              <Head>
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:site" content="@Hilton" />
                <meta property="og:url" content={`${OHW_BASE_URL_TRIMMED}/${locale}/offers/`} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={seo?.pageTitle || ''} />
                <meta property="og:description" content={seo?.metaDescription || ''} />
                <meta property="og:site_name" content="Hilton" />
                <meta property="og:image" content={data.firstImageForOg} />
              </Head>
            );
          }}
          offerCTAClick={(offer) => {
            sendCTAClickedOfferId(offer.id);
          }}
          renderInternalOfferLink={(offer) => (
            <ViewDetailsLink
              onClick={() => {
                sendCTAClickedOfferId(offer.id);

                sendNonCanUsaReward(offer.id);
              }}
              offer={offer}
              hrefPrefix={`/${locale}/offers/`}
            />
          )}
        />
      </div>
      <OffersFooter brandCode="WW" language={locale || 'en'} />
    </>
  );
}

export const getServerSideProps: GetServerSideProps = async function getServerSideProps({
  locale = 'en',
  res,
  req,
}) {
  const envVars = queryClientProps;
  const toggleHeader = req.headers['dx-toggles'] as string;
  const { queryClient } = getServerSideClients({
    ...envVars,
    response: res,
    appName: 'dx-offers-ui',
    customHeaders: {
      'dx-toggles': toggleHeader,
    },
  });

  const [{ featureToggles }, brandOfferListingResult] = await Promise.all([
    serverSideFeatureTogglesQuery(queryClient, {
      names: [ENTERPRISE_THEME_REFRESH_TOGGLE_NAME],
    }).catch((e) => {
      console.log('Error in serverSideFeatureTogglesQuery', e); //eslint-disable-line no-console
      return { featureToggles: [] };
    }),
    serverSideBrandOfferListingQuery(queryClient, {
      language: locale,
      brandCode: 'WW',
    }),
    serverSideBrandQuery(queryClient, { language: locale, brandCode: 'WW' }),
    serverSideNavigationQuery(queryClient, { language: locale, brandCode: 'WW' }),
  ]);

  const brandCode = 'HI';
  const seo = brandOfferListingResult?.brand?.page?.seo || {};
  const metrics: PageMetricsListing = {
    brandCode,
    brandName: brandOfferListingResult?.brand?.formalName || '',
    language: locale || 'en',
    primaryCategory: 'Portfolio',
    trackCallName: 'offerListingPageView',
  };

  return {
    props: {
      ...envVars,
      seo,
      metrics,
      dehydratedQueryState: JSON.parse(JSON.stringify(dehydrate(queryClient))),
      ...(await serverSideTranslations(locale)),
      ...getBrandRefreshProps({ featureToggles, brandCode }),
    },
  };
};
export default Index;
