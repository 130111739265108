import { slugifyOfferName } from '../utils/slugify-offer';
import { BrandLink } from '@dx-ui/osc-brand-buttons';
import { useTranslation } from 'next-i18next';
import { addCampaignId, type OfferListingFragment } from '@dx-ui/content-offers-components';

type Offer = OfferListingFragment['offers'][0];

export function ViewDetailsLink({
  offer,
  hrefPrefix,
  onClick,
}: {
  offer: Offer;
  hrefPrefix: string;
  onClick?: (offer: Offer) => void;
}) {
  const { t } = useTranslation('offers');
  return (
    <BrandLink
      url={addCampaignId(`${hrefPrefix}${slugifyOfferName(offer)}`)}
      onClick={() => onClick?.(offer)}
      className="w-full text-center md:w-auto"
      data-offer-slug={slugifyOfferName(offer)}
      data-offer-name={offer.name}
      aria-label={t('viewDetailsA11y', {
        offerName: offer?.name || '',
      })}
      target="_blank"
      showNewWindowIcon={false}
      variant="solid"
      label={t('viewDetails')}
    />
  );
}
